<template>
    <AppModal
        id="create-bid-modal"
        :show="isOpen"
        :title="$t('Reject Tender')"
        class="max-w-xl"
        @close="close"
        @closed="onClosed">
        <form
            class="contents"
            @submit.prevent="submit">
            <AppSelect
                v-model="form.reason"
                name="reason"
                label="Reason"
                :options="rejectionReasons"
                required />

            <div class="flex justify-end gap-2 pt-2">
                <AppButton
                    variant="outline"
                    @click="close">
                    {{ $t('Cancel') }}
                </AppButton>
                <AppButton
                    type="submit"
                    :disabled="form.processing || !form.reason"
                    :loading="form.processing">
                    {{ $t('Submit') }}
                </AppButton>
            </div>
        </form>
    </AppModal>
</template>

<script setup>
import { useForm } from '@inertiajs/vue3';
import Swal from 'sweetalert2';

const { isOpen, close, onClosed, context } = useModal('RejectTenderModal');

const form = useForm({
    reason: null,
    tender_ids: []
});

const rejectionReasons = ref([]);

provide('form', form);

watch(isOpen, () => {
    form.reset();
    form.clearErrors();
    form.tender_ids = context.value.tender_ids;

    rejectionReasons.value = context.value.rejection_reasons.reduce((rejectionReasons, reason) => {
        rejectionReasons[reason] = reason;
        return rejectionReasons;
    }, {});
});

function submit() {
    form.post(route('tenders.bulkReject'), {
        preserveScroll: true,
        onSuccess() {
            Swal.fire({
                title: 'Successfully Rejected'
            });
            close();
        }
    });
}
</script>
