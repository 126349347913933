<template>
    <AppCard
        :id="getId('load-*-pay-carrier', [load])"
        :title="$t('Pay Carrier')"
        theme="blue"
        content-class="p-4">
        <LoadBillCarrierRates :load="load" />

        <div class="flex items-center justify-between gap-2">
            <div class="flex flex-col gap-2">
                <AppHeading size="md">{{ $t('Accessorials') }}</AppHeading>
                <p>{{ formatMoney(accessorialTotal) }}</p>
            </div>
            <div class="flex flex-col gap-2">
                <AppHeading size="md">{{ $t('Advances') }}</AppHeading>
                <p>{{ formatMoney(advancesTotal) }}</p>
            </div>
            <div class="flex flex-col gap-2">
                <AppHeading size="lg">{{ $t('Total Carrier Pay') }}</AppHeading>
                <p class="text-lg">{{ formatMoney(carrierPayTotal + accessorialTotal - advancesTotal) }}</p>
            </div>
        </div>
    </AppCard>
</template>

<script setup>
import { formatMoney, getId } from '@/helpers';
import { sum } from 'lodash-es';

const { load } = defineProps({
    load: {
        type: Object,
        required: true
    }
});

const accessorialTotal = sum(
    load.accessorialTransactions.filter(item => item.pay_carrier).map(item => parseFloat(item.total))
);
const advancesTotal = sum(load.advances?.map(advance => parseFloat(advance.amount)) ?? [0]);
const carrierPayTotal = parseFloat(load.total_pay_carrier_amount);
</script>
