<template>
    <AppTablePill
        v-for="(filteredLoads, key) in loadTypes"
        :id="`loads-${key}-table`"
        :key="key"
        :columns="[
            { name: 'id', label: 'PO #' },
            { name: 'customer', label: 'Customer' },
            { name: 'customer_po', label: 'Customer PO #' },
            { name: 'carrier', label: 'Carrier' },
            { name: 'lane', label: 'Lane' },
            { name: 'date_time', label: 'Date/Time' },
            { name: 'status', label: 'Status' },
            { name: 'note', label: 'Note' },
            { name: 'actions', label: '' }
        ]"
        :data="loads[key]"
        :title="filteredLoads"
        :classes="{
            row: 'cursor-pointer',
            container: 'rounded-lg border-x-4 border-b-4 border-iel-blue  bg-iel-blue mb-6'
        }"
        @row-click="openLoad">
        <template #id-cell="{ row }">
            <Link
                :href="route('loads.show', row.id)"
                class="flex items-center gap-2">
                <div class="flex flex-1 gap-2 underline">
                    {{ row.id }}
                </div>
            </Link>
        </template>
        <template #customer-cell="{ row }">
            <p>{{ row.customer?.name }}</p>
        </template>
        <template #carrier-cell="{ row }">
            <p>{{ row.carrier?.name }}</p>
        </template>
        <template #lane-cell="{ row }">
            <div v-if="row.lane.start.city != null || row.lane.end.city != null">
                <p>{{ row.lane.start.city }}, {{ row.lane.start.region }}</p>
                <p>{{ row.lane.end.city }}, {{ row.lane.end.region }}</p>
            </div>
            <p v-else></p>
        </template>
        <template #date_time-cell="{ row }">
            <div
                v-if="formatLoadboardLane(row)"
                class="space-y-1">
                <p>{{ formatLoadboardLane(row).startDate }}</p>
                <p>{{ formatLoadboardLane(row).endDate }}</p>
            </div>
        </template>
        <template #note-cell="{ row }">
            <p class="max-w-xs text-balance">{{ note(row) }}</p>
        </template>
        <template #actions-cell="{ row }">
            <div class="flex w-full justify-end gap-4">
                <LoadListWarnings :load="row" />
                <LoadListActionMenu
                    :load="row"
                    :options="options" />
            </div>
        </template>
    </AppTablePill>
</template>

<script setup>
import AuthenticatedLayout from '@/Layouts/AuthenticatedLayout.vue';
import LoadLayout from '@/Layouts/LoadLayout.vue';
import { router } from '@inertiajs/vue3';
import { formatLoadboardLane } from '@/helpers';

defineOptions({
    layout: [AuthenticatedLayout, LoadLayout]
});

const { loads, options } = defineProps({
    loads: {
        type: Object,
        required: true,
        default: () => ({})
    },
    options: {
        type: Object,
        required: true,
        default: () => ({})
    }
});

const loadTypes = {
    today: 'Today',
    transit: 'In-Transit',
    billing: 'Billing',
    future: 'Future'
};

function openLoad(row) {
    router.visit(route('loads.show', row.id));
}

function truncateString(str) {
    const adjustedMaxLength = window.innerWidth >= 1024 ? 75 : 5;

    return str.length > adjustedMaxLength ? str.substring(0, adjustedMaxLength) + '...' : str;
}

function note(row) {
    if (row.primary_note) {
        return row.primary_note;
    } else if (typeof row.latest_note.content !== 'undefined') {
        return truncateString(row.latest_note.content);
    }
    return '';
}
</script>
