<template>
    <AppCard
        :id="getId('tender-*-stops-list', [tender])"
        theme="blue"
        :title="$t('Stops')">
        <template #nav>
            <div
                v-if="headerNav === 'TOGGLE_MAP_AND_ADD_STOP'"
                class="flex items-center gap-3">
                <div
                    :id="getId('tender-*-stops-map-toggle', [tender])"
                    class="flex items-center gap-2">
                    <span class="text-sm text-white">{{ $t('Show Map') }}</span>
                    <AppToggle
                        v-model="showMap"
                        @change="saveShowMapState" />
                </div>
            </div>
            <div
                v-if="headerNav === 'TOTAL_MILES'"
                :id="getId('tender-*-stops-total-miles', [tender])"
                class="mr-4 text-sm font-bold text-white">
                {{ $t('Total Miles:') }}
                {{ tender.trip_miles }}
            </div>
        </template>

        <!-- MAP -->
        <div
            v-if="showMap"
            class="p-6">
            <TenderMap :tender="tender" />
        </div>

        <!-- STOPS TABLE -->
        <div class="rounded-b">
            <table
                :id="getId('tender-*-stops-table', [tender])"
                class="w-full text-sm">
                <thead class="bg-iel-mercury">
                    <tr>
                        <th
                            v-for="stopColumn in stopColumns"
                            :key="stopColumn.id"
                            class="p-2 text-left">
                            {{ stopColumn.field }}
                        </th>
                    </tr>
                </thead>
                <Draggable
                    v-model="stops"
                    group="people"
                    tag="tbody"
                    item-key="id"
                    handle="[data-handle]"
                    ghost-class="pending-drop-target"
                    @change="sortStops"
                    @start="selectedStop = null">
                    <template #item="{ element: stop }">
                        <tr
                            :id="getId('tender-*-stops-row-*', [tender, stop])"
                            class="border-t border-iel-mercury"
                            :class="{ 'cursor-pointer': !stop.open, hidden: stop.hidden }"
                            @click="!stop.open && selectStopId(stop.id)">
                            <template v-if="stop.open">
                                <td :colspan="stopColumns.length + 2">
                                    <div class="bg-iel-mercury p-3">
                                        <TenderStopForm v-bind="{ stop, tender }" />
                                    </div>
                                </td>
                            </template>
                            <template v-else>
                                <td
                                    v-for="stopColumn in stopColumns"
                                    :id="getId('tender-*-stops-row-*-cell-*', [tender, stop, stopColumn])"
                                    :key="stopColumn.id"
                                    class="p-2">
                                    <div v-html="stop[stopColumn.id]" />
                                </td>
                            </template>
                        </tr>
                    </template>
                </Draggable>
            </table>
            <button
                v-if="hidesRows && stops.length > 4"
                :id="getId('tender-*-stops-toggle-hidden-rows-button', [tender])"
                class="block w-full cursor-pointer bg-iel-mercury py-2 text-center text-sm"
                @click="isListExpanded = !isListExpanded">
                <span v-if="hiddenStopsCount">Show {{ hiddenStopsCount }} more</span>
                <span v-else>Show less</span>
            </button>
        </div>
    </AppCard>
</template>

<script setup>
import Draggable from 'vuedraggable';
// import { useI18n } from 'vue-i18n';
import { get } from 'lodash-es';
import { timezones as defaultTimezones } from '@/data';
import { formatStopDate, getId } from '@/helpers';

// const { t } = useI18n();

const { tender, headerNav, displayColumns, hidesRows, openStopId } = defineProps({
    tender: Object,
    headerNav: {
        type: String,
        default: 'TOGGLE_MAP_AND_ADD_STOP',
        validator: value => ['TOGGLE_MAP_AND_ADD_STOP', 'TOTAL_MILES'].includes(value)
    },
    displayColumns: {
        type: Array,
        default: () => ['type', 'location', 'time', 'appt', 'po', 'contact_number']
    },
    hidesRows: Boolean,
    openStopId: Number
});

const showMap = ref(false);

const isListExpanded = ref(false);

const columns = ref([
    { field: 'Stop Type', id: 'type' },
    { field: 'Location', id: 'location' },
    { field: 'Date & Time', id: 'time' },
    { field: 'Appt #', id: 'appt' },
    { field: 'PO #', id: 'po' },
    { field: 'Phone #', id: 'phone' }
]);

const stopColumns = computed(() => columns.value.filter(column => displayColumns.includes(column.id)));

const selectedStopId = ref(null);

const selectedStop = computed(() => {
    if (!selectedStopId.value) return null;
    return tender.stops.find(stop => stop.id === selectedStopId.value);
});

function formatStopTableWindowTime(stop) {
    const beginsAt =
        formatStopDate(stop.stop_window_begins_at) +
        ' ' +
        (defaultTimezones[stop.stop_window_begins_at_timezone] || '');
    const endsAt = stop.stop_window_ends_at
        ? ' - ' +
          formatStopDate(stop.stop_window_ends_at) +
          ' ' +
          (defaultTimezones[stop.stop_window_ends_at_timezone] || '')
        : '';
    return stop.has_stop_window ? beginsAt + endsAt : beginsAt;
}

const stops = computed(() => {
    let rows = tender.stops.map(stop => {
        const commodityTypeDetails = getLocationCommodityTypeDetails(tender.commodity_type, stop);

        return {
            id: stop.id,
            type: stop.type === 'pickup' ? 'PU' : 'DEL',
            location: getLocationCell(stop),
            order: stop.order,
            time: formatStopTableWindowTime(stop)
                ? formatStopDate(stop.stop_window_begins_at) +
                  ' - ' +
                  formatStopDate(stop.stop_window_ends_at) +
                  ' ' +
                  (defaultTimezones[stop.stop_window_ends_at_timezone] || '')
                : formatStopDate(stop.stop_window_begins_at),
            appt: stop.appointment_number,
            po: tender.po_number,
            mode: stop.has_stop_window ? 'FCFS' : 'Appt',
            'hours-operation': commodityTypeDetails.hours_of_operation,
            'hours-dock': commodityTypeDetails.dock_hours,
            'internal-note': stop.internal_note,
            phone: stop.location.phone_number || ' - '
        };
    });

    if (hidesRows && !isListExpanded.value && rows.length > 4) {
        rows = rows.map((stop, index) => ({ ...stop, hidden: index > 3 }));
    }

    if (!selectedStop.value) {
        return rows;
    }

    return rows.flatMap(stop =>
        stop.id === selectedStopId.value ? [stop, { ...selectedStop.value, open: true }] : [stop]
    );
});

const hiddenStopsCount = computed(() => stops.value.filter(stop => stop.hidden).length);

onMounted(() => {
    const savedShowMapState = localStorage.getItem(`showTenderMap-${tender.id}`);
    if (savedShowMapState !== null) {
        showMap.value = JSON.parse(savedShowMapState);
    }

    if (openStopId) {
        selectStopId(openStopId);
    }
});

function selectStopId(stopId) {
    if (stopId === selectedStopId.value) return (selectedStopId.value = null);
    selectedStopId.value = stopId;
}

function getLocationCell(stop) {
    return `
        <a href="${route('locations.show', stop.location)}" target="_blank" class="underline font-bold">${stop.location.name}</a>
        ${stop.location.address.city}, ${stop.location.address.region}
        ${stop.location.address.postal_code}
    `;
}

function getLocationCommodityTypeDetails(commodityType, stop) {
    const commodityTypeDetails = get(
        stop,
        ['location', stop.type === 'pickup' ? 'receivingDetails' : 'shippingDetails', 'commodityTypeDetails'],
        []
    );

    return commodityTypeDetails.find(item => item.commodity_type === commodityType) ?? {};
}

function saveShowMapState() {
    localStorage.setItem(`showTenderMap-${tender.id}`, JSON.stringify(showMap.value));
}
</script>

<style scoped>
.pending-drop-target {
    @apply bg-iel-light-blue;
}
.pending-drop-target > * {
    @apply opacity-0;
}
</style>
