<template>
    <div class="my-4 flex justify-between">
        <AppHeading
            :id="getId('load-*-billing-grand-total-heading', [load])"
            size="xl">
            {{ $t('Total') }}
        </AppHeading>
        <AppHeading
            :id="getId('load-*-billing-grand-total-amount', [load])"
            size="xl">
            {{ grandTotal }}
        </AppHeading>
    </div>
</template>

<script setup>
import { sum } from 'lodash-es';
import { formatMoney, getId } from '@/helpers.js';
import AppHeading from '@/Components/AppHeading.vue';

const { load } = defineProps({
    load: {
        type: Object,
        required: true
    }
});

const grandTotal = formatMoney(
    parseFloat(load.total_customer_billing_amount) +
        sum(load.accessorialTransactions.filter(item => item.bill_to_customer).map(item => parseFloat(item.total)))
);
</script>
